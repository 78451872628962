<template>

  <div class="row">
    <div class="col-sm-12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Fontawesome Icon</h4>
        </template>
        <template v-slot:body>
          <p>Use Class <code>&lt;i class="fa fa-address-book"&gt;&lt;/i&gt;</code></p>
          <div class="row mt-3">
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-address-book" aria-hidden="true"></i>address-book</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-envelope-open" aria-hidden="true"></i>envelope-open</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-id-card" aria-hidden="true"></i>id-card</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-telegram" aria-hidden="true"></i>telegram</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-user-circle" aria-hidden="true"></i>user-circle</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-address-card" aria-hidden="true"></i>area-chart</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-asterisk" aria-hidden="true"></i>asterisk</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-car" aria-hidden="true"></i>car</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-bars" aria-hidden="true"></i>bars</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-battery-full" aria-hidden="true"></i>battery-full</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-bluetooth" aria-hidden="true"></i>bluetooth</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-book" aria-hidden="true"></i>book</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-bug" aria-hidden="true"></i>bug</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-building" aria-hidden="true"></i>building</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-calculator" aria-hidden="true"></i>calculator</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-calendar" aria-hidden="true"></i>calendar</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-camera" aria-hidden="true"></i>camera</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="far fa-comments" aria-hidden="true"></i>comments</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-crop" aria-hidden="true"></i>crop</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-download" aria-hidden="true"></i>download</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-folder" aria-hidden="true"></i>folder</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-gift" aria-hidden="true"></i>gift</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-users" aria-hidden="true"></i>users</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-hashtag" aria-hidden="true"></i>hashtag</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-home" aria-hidden="true"></i>home</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-lock" aria-hidden="true"></i>lock</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-graduation-cap" aria-hidden="true"></i>graduation-cap</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-paper-plane" aria-hidden="true"></i>paper-plane</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-star" aria-hidden="true"></i>star</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-tag" aria-hidden="true"></i>tag</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-trash" aria-hidden="true"></i>trash</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-upload" aria-hidden="true"></i>upload</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-university" aria-hidden="true"></i>university</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-wifi" aria-hidden="true"></i>wifi</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-thumbs-up" aria-hidden="true"></i>thumbs-up</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-train" aria-hidden="true"></i>train</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fa fa-file" aria-hidden="true"></i>file</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-snapchat" aria-hidden="true"></i>snapchat</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-twitter" aria-hidden="true"></i>twitter</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-wordpress" aria-hidden="true"></i>wordpress</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-html5" aria-hidden="true"></i>html</a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a class="iq-icons-list" href="#"><i class="fab fa-css3" aria-hidden="true"></i>css</a>
            </div>
            <div class="col-sm-12 text-center mt-3">
              <a href="https://fontawesome.com/v4.7.0/" target="blank" class="btn btn-primary">View All Icon</a>
            </div>
          </div>
        </template>
      </iq-card>
    </div>
  </div>

</template>
<script>
// import { socialvue } from '../../config/pluginInit'
export default {
  name: 'IconFontAwesome5',
  mounted () {
    // socialvue.index()
  }
}
</script>
